<template>
    <div>
        <v-card color="secondary" :loading="$wait.is('fetch tasks')">
            <v-card-title class="text-subtitle-1 primary--text" style="min-height:72px;">
                <span v-t="'vvtProcessEditor.workflow.title'" />
                <v-spacer></v-spacer>
                <v-btn icon color="primary" @click="workflowActivityDialog = !workflowActivityDialog" :disabled="!activityHasWorkflowTask">
                    <v-icon>mdi-history</v-icon>
                </v-btn>
                <v-btn icon color="primary" @click="toggleWorkflow()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <BaseComponentLoader forName="loadingTasks" linear />
            <v-card-text class="pa-0">
                <div>
                    <v-timeline align-top dense class="secondary">
                        <v-timeline-item
                            v-if="!canEditWorkflow || modifiedByUser"
                            color="secondary"
                            icon-color="warning"
                            fill-dot
                            icon="mdi-alert-circle-outline"
                        >
                        <div class="mr-3">
                            <v-card :elevation="1" color="transparent">
                                <v-card-text class="py-1 d-block text-h6 warning--text text--darken-2" v-t="!canEditWorkflow ? 'vvtProcessEditor.workflow.notAuthorizedAction' : 'saveRecordAlert'" />
                                <v-banner
                                    single-line
                                    color="warning"
                                    :height="6"
                                ></v-banner>
                            </v-card>
                        </div>
                        </v-timeline-item>
                        <v-timeline-item v-if="showInitWorkflowButton" hide-dot class="mr-3">
                            <v-btn
                                :disabled="$wait.is('fetch tasks') || !canEditWorkflow || modifiedByUser"
                                @click="openAssignmentDialog('init', startingWorkflowTargetStatus)"
                                color="success"
                                block
                                small>{{ $t('vvtProcessEditor.workflow.edit_workflow_init.start_workflow') }}<v-spacer /><v-icon left v-text="workflowStatusIcons[currentWorkflowStepIndex]" /></v-btn>
                        </v-timeline-item>
                        <TimelineItem
                            key="draft"
                            item-status="draft"
                            :item-icon="workflowStatusIcons[0]"
                            target-status="review"
                            :workflow-status-linear="workflowStatusLinear"
                            :item-title="$t('states.draft')"
                            :next-step-text="$tc('vvtProcessEditor.workflow.edit_workflow_draft.nextStepInfoText')"
                            :workflow-action-enabled="status === 'draft' && !showInitWorkflowButton && (activityHasWorkflowTask || getCompanyGroupSettings('allowUserToStartWorkflow')) && canEditWorkflow"
                        >
                            <template v-slot:actions>
                                <v-card-actions>
                                    <v-btn
                                        @click="openAssignmentDialog('draft', 'draft')"
                                        :disabled="$wait.is('fetch tasks') || !canEditWorkflow || modifiedByUser"
                                        small
                                        :class="{'primary--text text--darken-1': !$wait.is('fetch tasks') && canEditWorkflow && !modifiedByUser}"
                                        class="warning lighten-1 btn--default-transform"
                                        block>{{ $t('vvtProcessEditor.workflow.edit_workflow_draft.to_draft.openAssignmentButtonText') }}<v-spacer /><v-icon left v-text="workflowStatusIcons[0]" /></v-btn>
                                </v-card-actions>
                                <v-card-actions>
                                    <v-btn
                                        @click="openAssignmentDialog('draft', 'review')"
                                        :disabled="$wait.is('fetch tasks') || !canEditWorkflow || modifiedByUser"
                                        small
                                        color="success"
                                        class="btn--default-transform"
                                        block>{{ $t('vvtProcessEditor.workflow.edit_workflow_draft.to_review.openAssignmentButtonText') }}<v-spacer /><v-icon left v-text="workflowStatusIcons[1]" /></v-btn>
                                </v-card-actions>
                            </template>
                        </TimelineItem>
                        <TimelineItem
                            key="review"
                            item-status="review"
                            :item-icon="workflowStatusIcons[1]"
                            target-status="approval"
                            :workflow-status-linear="workflowStatusLinear"
                            :item-title="$t('states.review')"
                            :next-step-text="$t('vvtProcessEditor.workflow.edit_workflow_review.nextStepInfoText')"
                            :workflow-action-enabled="status === 'review' && !showInitWorkflowButton && (activityHasWorkflowTask || getCompanyGroupSettings('allowUserToStartWorkflow')) && canEditWorkflow"
                        >
                            <template v-slot:actions>
                                <v-card-actions>
                                    <v-btn
                                        @click="openAssignmentDialog('review', 'draft')"
                                        :disabled="$wait.is('fetch tasks') || !canEditWorkflow || modifiedByUser"
                                        small
                                        :class="{'primary--text text--darken-1': !$wait.is('fetch tasks') && canEditWorkflow && !modifiedByUser}"
                                        class="warning lighten-1 btn--default-transform"
                                        block>{{ $t('vvtProcessEditor.workflow.edit_workflow_review.to_draft.openAssignmentButtonText') }}<v-spacer /><v-icon left v-text="workflowStatusIcons[0]" /></v-btn>
                                </v-card-actions>
                                <v-card-actions>
                                    <v-btn
                                        @click="openAssignmentDialog('review', 'approval')"
                                        :disabled="$wait.is('fetch tasks') || !canEditWorkflow || modifiedByUser"
                                        small
                                        color="success"
                                        class="btn--default-transform"
                                        block>{{ $t('vvtProcessEditor.workflow.edit_workflow_review.to_approval.openAssignmentButtonText') }}<v-spacer /><v-icon left v-text="workflowStatusIcons[2]" /></v-btn>
                                </v-card-actions>
                            </template>
                        </TimelineItem>
                        <TimelineItem
                            key="approval"
                            item-status="approval"
                            :item-icon="workflowStatusIcons[2]"
                            target-status="release"
                            :workflow-status-linear="workflowStatusLinear"
                            :item-title="$t('states.approval')"
                            :next-step-text="$tc('vvtProcessEditor.workflow.edit_workflow_approval.nextStepInfoText', assignedOwners.length)"
                            :workflow-action-enabled="status === 'approval' && !showInitWorkflowButton && (activityHasWorkflowTask || getCompanyGroupSettings('allowUserToStartWorkflow')) && canEditWorkflow"
                        >
                            <template v-slot:actions>
                                <v-card-actions>
                                    <v-btn
                                        @click="openAssignmentDialog('approval', 'draft')"
                                        :disabled="$wait.is('fetch tasks') || !canEditWorkflow || modifiedByUser"
                                        small
                                        :class="{'primary--text text--darken-1': !$wait.is('fetch tasks') && canEditWorkflow && !modifiedByUser}"
                                        class="warning lighten-1 btn--default-transform"
                                        block>{{ $t('vvtProcessEditor.workflow.edit_workflow_approval.to_draft.openAssignmentButtonText') }}<v-spacer /><v-icon left v-text="workflowStatusIcons[0]" /></v-btn>
                                </v-card-actions>
                                <v-card-actions>
                                    <v-btn
                                        @click="openAssignmentDialog('approval', 'review')"
                                        :disabled="$wait.is('fetch tasks') || !canEditWorkflow || modifiedByUser"
                                        small
                                        :class="{'primary--text text--darken-1': !$wait.is('fetch tasks') && canEditWorkflow && !modifiedByUser}"
                                        class="warning lighten-3 btn--default-transform"
                                        block>{{ $t('vvtProcessEditor.workflow.edit_workflow_approval.to_review.openAssignmentButtonText') }}<v-spacer /><v-icon left v-text="workflowStatusIcons[1]" /></v-btn>
                                </v-card-actions>
                                <v-card-actions>
                                    <v-btn
                                        @click="openAssignmentDialog('approval', 'release')"
                                        :disabled="$wait.is('fetch tasks') || !canEditWorkflow || modifiedByUser"
                                        small color="success" class="btn--default-transform"
                                        block>{{ $t('vvtProcessEditor.workflow.edit_workflow_approval.to_release.openAssignmentButtonText') }}<v-spacer /><v-icon left v-text="workflowStatusIcons[3]" /></v-btn>
                                </v-card-actions>
                            </template>
                        </TimelineItem>
                        <TimelineItem
                            key="release"
                            item-status="release"
                            :item-icon="workflowStatusIcons[3]"
                            target-status="finalised"
                            :workflow-status-linear="workflowStatusLinear"
                            :item-title="$t('states.release')"
                            :next-step-text="$t('vvtProcessEditor.workflow.edit_workflow_release.nextStepInfoText')"
                            :workflow-action-enabled="status === 'release' && !showInitWorkflowButton && (activityHasWorkflowTask || getCompanyGroupSettings('allowUserToStartWorkflow')) && canEditWorkflow"
                        >
                            <template v-slot:actions>
                                <v-card-actions>
                                <v-btn
                                    @click="openAssignmentDialog('release', 'draft')"
                                    :disabled="$wait.is('fetch tasks') || !canEditWorkflow || modifiedByUser"
                                    small
                                    :class="{'primary--text text--darken-1': !$wait.is('fetch tasks') && canEditWorkflow && !modifiedByUser}"
                                    class="warning lighten-1 btn--default-transform"
                                    block>{{ $t('vvtProcessEditor.workflow.edit_workflow_release.to_draft.openAssignmentButtonText') }}<v-spacer /><v-icon left v-text="workflowStatusIcons[0]" /></v-btn>
                                </v-card-actions>
                                <v-card-actions>
                                    <v-btn
                                        @click="openAssignmentDialog('release', 'review')"
                                        :disabled="$wait.is('fetch tasks') || !canEditWorkflow || modifiedByUser"
                                        small
                                        :class="{'primary--text text--darken-1': !$wait.is('fetch tasks') && canEditWorkflow && !modifiedByUser}"
                                        class="warning lighten-3 btn--default-transform"
                                        block>{{ $t('vvtProcessEditor.workflow.edit_workflow_release.to_review.openAssignmentButtonText') }}<v-spacer /><v-icon left v-text="workflowStatusIcons[1]" /></v-btn>
                                </v-card-actions>
                                <v-card-actions>
                                    <v-btn
                                        @click="openAssignmentDialog('release', 'finalised')"
                                        :disabled="$wait.is('fetch tasks') || !canEditWorkflow || modifiedByUser"
                                        small
                                        color="success"
                                        class="btn--default-transform"
                                        block>{{ $t('vvtProcessEditor.workflow.edit_workflow_release.to_finalised.openAssignmentButtonText') }}<v-spacer /><v-icon left v-text="workflowStatusIcons[4]" /></v-btn>
                                </v-card-actions>
                            </template>
                        </TimelineItem>
                        <TimelineItem
                            key="finalised"
                            item-status="finalised"
                            :item-icon="workflowStatusIcons[4]"
                            target-status="draft"
                            :workflow-status-linear="workflowStatusLinear"
                            :item-title="$t('states.finalised')"
                            :next-step-text="$t('vvtProcessEditor.workflow.edit_workflow_finalised.nextStepInfoText')"
                            :workflow-action-enabled="status === 'finalised' && !showInitWorkflowButton && (activityHasWorkflowTask || getCompanyGroupSettings('allowUserToStartWorkflow')) && canEditWorkflow"
                        >
                            <template v-slot:actions>
                                <v-card-actions>
                                <v-btn
                                    @click="openAssignmentDialog('finalised', 'draft')"
                                    :disabled="$wait.is('fetch tasks') || !canEditWorkflow || modifiedByUser"
                                    small
                                    :class="{'primary--text text--darken-1': !$wait.is('fetch tasks') && canEditWorkflow && !modifiedByUser}"
                                    class="warning lighten-1 btn--default-transform"
                                    block>{{ $t('vvtProcessEditor.workflow.edit_workflow_finalised.to_draft.openAssignmentButtonText') }}<v-spacer /><v-icon left v-text="workflowStatusIcons[0]" /></v-btn>
                                </v-card-actions>
                                <v-card-actions>
                                    <v-btn
                                        @click="openAssignmentDialog('finalised', 'archived')"
                                        :disabled="$wait.is('fetch tasks') || !canEditWorkflow || modifiedByUser || !$hasRole('ROLE_TENANT_EDITOR')"
                                        small
                                        :class="{'primary--text text--darken-1': !$wait.is('fetch tasks') && canEditWorkflow && !modifiedByUser && $hasRole('ROLE_TENANT_EDITOR')}"
                                        class="btn--default-transform"
                                        block>{{ $t('vvtProcessEditor.workflow.edit_workflow_finalised.to_archived.openAssignmentButtonText') }}<v-spacer /><v-icon left v-text="workflowStatusIcons[5]" /></v-btn>
                                </v-card-actions>
                            </template>
                        </TimelineItem>
                        <TimelineItem
                            v-if="status === 'archived'"
                            key="archived"
                            item-status="archived"
                            :item-icon="workflowStatusIcons[5]"
                            target-status="draft"
                            :workflow-status-linear="workflowStatusLinear"
                            :item-title="$t('states.archived')"
                            :next-step-text="$t('vvtProcessEditor.workflow.edit_workflow_archived.nextStepInfoText')"
                            :workflow-action-enabled="status === 'archived' && !showInitWorkflowButton && (activityHasWorkflowTask || getCompanyGroupSettings('allowUserToStartWorkflow')) && canEditWorkflow"
                        >
                            <template v-slot:actions>
                                <v-card-actions>
                                    <v-btn
                                        @click="openAssignmentDialog('archived', 'draft')"
                                        :disabled="$wait.is('fetch tasks') || !canEditWorkflow || modifiedByUser"
                                        small
                                        :class="{'primary--text text--darken-1': !$wait.is('fetch tasks') && canEditWorkflow && !modifiedByUser}"
                                        class="btn--default-transform warning lighten-1"
                                        block>{{ $t('vvtProcessEditor.workflow.edit_workflow_archived.to_draft.openAssignmentButtonText') }}<v-spacer /><v-icon left v-text="workflowStatusIcons[0]" /></v-btn>
                                </v-card-actions>
                            </template>
                        </TimelineItem>
                    </v-timeline>
                    <v-dialog
                        v-model="assignmentDialog"
                        :max-width="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.xl ? '60%' : '80%' : '100%'"
                        scrollable>
                        <v-card>
                            <v-toolbar 
                                tile
                                color="primary"
                                flat
                            >
                                <v-card-title class="headline white--text" v-text="assignmentDialogContent.title" />
                                <v-spacer></v-spacer>
                                <v-tooltip bottom color="primary">
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-on="on"
                                            depressed
                                            icon
                                            color="white"
                                            class="mr-2"
                                            @click="assignmentDialog = false"
                                        >
                                            <v-icon v-text="'mdi-close'" />
                                        </v-btn>
                                    </template>
                                    <span v-text="$t('close')"></span>
                                </v-tooltip>
                            </v-toolbar>
                        <v-card-text class="pt-3 pb-0">
                           <div class="px-0 mx-n3">
                                <v-alert
                                    v-if="assignmentDialogContent.text"
                                    dense
                                    outlined
                                    class="ma-3 mt-2"
                                    icon="mdi-information-outline"
                                    type="info">
                                    <div>
                                        <span v-t="assignmentDialogContent.text" />
                                        <span v-if="assignmentDialogContent.notificationEmail" v-text="assignmentDialogContent.notificationEmail" class="pl-2" />
                                    </div>
                                </v-alert>
                                <v-list v-if="assignmentDialogContent.items.length" class="mx-n3 mt-6 mb-2" dense color="transparent" :disabled="!canEditWorkflow || modifiedByUser || $wait.is('workflow step')">
                                    <v-list-item-group v-model="assignmentDialogContent.selectedPersonIds" multiple color="primary">
                                        <template v-for="(person, index) in assignmentDialogContent.items">
                                            <v-tooltip :key="person.id" :disabled="personIsAssignable(person)" bottom color="error lighten-2">
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on">
                                                        <v-list-item @click.stop.native :value="person.contactId" class="pl-10" :disabled="!personIsAssignable(person) || (assignmentDialogContent.items.length === 1 && assignmentDialogContent.selectedPersonIds.length === 1)">
                                                            <template v-slot:default="{ active }">
                                                                <v-list-item-action>
                                                                    <v-icon v-text="!personIsAssignable(person) ? 'mdi-account-alert' : active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'" :color="!personIsAssignable(person) ? 'error lighten-2' : active ? 'primary' : 'grey lighten-1'"></v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title v-text="person.fullName"></v-list-item-title>
                                                                </v-list-item-content>
                                                                <v-list-item-action v-if="false">
                                                                    <v-btn @click.stop icon color="primary" :disabled="!canEditWorkflow || modifiedByUser || !active"><v-icon color="primary">mdi-format-list-checks</v-icon></v-btn>
                                                                </v-list-item-action>
                                                            </template>
                                                        </v-list-item>
                                                    </div>
                                                </template>
                                                <span v-t="person.email ? 'vvtProcessEditor.workflow.warningContactWithoutUser' : 'vvtProcessEditor.workflow.warningContactWithoutEmail'" />
                                            </v-tooltip>
                                            <v-divider
                                                v-if="index + 1 < assignmentDialogContent.items.length"
                                                :key="index"
                                            ></v-divider>
                                        </template>
                                    </v-list-item-group>
                                </v-list>
                                <v-container fluid class="pr-0 pt-3 pl-2" :class="{ 'pl-12 pt-6': $vuetify.breakpoint.lgAndUp }">
                                    <v-row no-gutters align="start" align-content="space-between">
                                        <v-col grow :order="2" :order-md="1">
                                            <v-text-field
                                                :label="$t('vvtProcessEditor.workflow.assignmentNotificationSubject.label')"
                                                v-model="assignmentDialogContent.notificationSubject"
                                                :loading="$wait.is('fetch notification template') || $wait.is('workflow step')"
                                                outlined
                                                :disabled="assignmentDialogContent.notificationSubjectDisabled"
                                                spellcheck="true"
                                                class="mt-7"
                                                data-vv-name="notificationSubject"
                                                v-validate="'required'"
                                                :error-messages="errors.collect('notificationSubject')"
                                                required
                                            />
                                            <LeaTipTapEditor
                                                v-model="assignmentDialogContent.notificationContent"
                                                :label="$t('vvtProcessEditor.workflow.assignmentNotificationContent.label')"
                                                data-vv-name="notificationContent"
                                                :disabled="$wait.is('fetch notification template') || $wait.is('workflow step') || !canEditWorkflow || modifiedByUser"
                                                :loading="$wait.is('fetch notification template') || $wait.is('workflow step')"
                                                required
                                                spellcheck="true"
                                                disableExtendedFormat
                                                persistent-hint
                                                class="pt-1"
                                            />
                                        </v-col>
                                        <v-col cols="12" :order="1" :order-md="2" :md="4" :xl="3">
                                            <v-card-title class="caption py-0 ml-3" v-t="'vvtProcessEditor.workflow.assignmentNotificationDueDate.hint'"></v-card-title>
                                            <v-date-picker
                                                v-model="assignmentDialogContent.notificationDueDate"
                                                :disabled="!canEditWorkflow || modifiedByUser || $wait.is('workflow step')"
                                                :min="datePickerMinDate"
                                                :locale="$i18n.locale"
                                                :scrollable="false"
                                                show-week
                                                :first-day-of-week="1"
                                                reactive
                                                width="100%"
                                                :class="{ 'ma-2': $vuetify.breakpoint.mdAndUp }"
                                                class="ml-6 v-datepicker--active-header"
                                                :error-messages="errors.collect('notificationDueDate')"
                                                data-vv-name="notificationDueDate"
                                                v-validate="'required'"
                                                required
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                           </div>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click.stop.native="setWorkflowAction()"
                                :disabled="sendNotificationDisabled"
                                :loading="$wait.is('workflow step') || $wait.is('fetch notification template')"
                                :class="assignmentDialogContent.submitButtonClass"
                                class="btn--default-transform"
                            >{{ assignmentDialogContent.submitButtonText }}</v-btn>
                        </v-card-actions>
                        <v-snackbar
                            v-model="errorMessage"
                            color="error"
                            :timeout="6000"
                            centered
                        >
                            {{ $t('genericErrorMessage') }}
                            <v-btn text icon @click="errorMessage = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-snackbar>
                        </v-card>
                    </v-dialog>
                </div>
            </v-card-text>
        </v-card>
        <v-dialog
            v-model="workflowActivityDialog"
            :max-width="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.xl ? '60%' : '80%' : '100%'"
            scrollable>
            <v-card>
                <v-toolbar 
                    tile
                    color="primary"
                    flat
                >
                    <v-card-title class="headline white--text" v-t="'vvtProcessEditor.workflow.history.headline'" />
                    <v-spacer></v-spacer>
                    <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                depressed
                                icon
                                color="white"
                                class="mr-2"
                                @click="fetchTasks()"
                                :disabled="modifiedByUser"
                                :loading="$wait.is('fetch tasks')"
                            >
                                <v-icon v-text="'mdi-refresh-circle'" />
                            </v-btn>
                        </template>
                        <span v-t="'vvtProcessEditor.workflow.history.refreshTooltip'"></span>
                    </v-tooltip>
                    <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                depressed
                                icon
                                color="white"
                                class="mr-2"
                                @click="workflowActivityDialog = false"
                            >
                                <v-icon v-text="'mdi-close'" />
                            </v-btn>
                        </template>
                        <span v-text="$t('close')"></span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>
                    <v-data-table
                        :headers="workflowTasksDataTableHeader"
                        :items="workflowTasksDataTable"
                        :items-per-page="5"
                        :options="{'sortBy':['updatedAt'],'sortDesc':[true]}"
                        :custom-sort="taskTableSort"
                        class="elevation-1 mt-6"
                        :loading="$wait.is('fetch tasks')"
                        :footer-props="{
                            itemsPerPageOptions: [5, 10, 25, 50, -1],
                            itemsPerPageText: $t('perPage'),
                            itemsPerPageAllText: $t('all'),
                            pageText: '{0}-{1} ' + $t('from') + ' {2}'
                        }"
                        single-expand
                        show-expand
                        :expanded.sync="workflowTasksDataTableExpanded"
                        @click:row="onClickWorkflowTask"
                    > 
                        <template v-slot:[`item.data-table-expand`]="{isExpanded}">
                            <v-btn icon>
                                <v-icon>
                                    {{ isExpanded ? 'mdi-menu-down' : 'mdi-menu-right' }}
                                </v-icon>
                            </v-btn>
                        </template>                       
                        <template v-slot:expanded-item="{ headers, item }">
                            <td class="pa-0 py-3" :colspan="headers.length">
                                <div>
                                    <div class="px-3 py-1 pl-9" v-html="$nl2br(item.description)" />
                                </div>
                            </td>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <BaseResponseDialog
            v-model="baseResponseDialog"
            errorDialog
            :headline="$t('vvtProcessEditor.workflow.sendNotification.errorNoMailAddress.headline')"
            :message="$t('vvtProcessEditor.workflow.sendNotification.errorNoMailAddress.message')"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import http from '@/utils/axios';
import { dateTypes, parseISO, formatDate } from '@/utils/dateFns';
import TimelineItem from './TimelineItem.vue';
import { getDetails, getRoles } from '@/utils/auth';
import LeaTipTapEditor from '@/components/Input/LeaTipTapEditor.vue';

export default {
    name: 'InfoBarWorkflow',
    components: {
        TimelineItem,
        LeaTipTapEditor
    },
    $_veeValidate: {
        validator: 'new', // see https://github.com/baianat/vee-validate/issues/1547
    },
    provide() {
      return {
        $validator: this.$validator,
      }
    },
    props: {
        template: {
            type: Boolean,
            default: false,
        },
        editorMode: {
            type: String,
            default: null,
        },
        company: {
            type: [Object, Array],
            default: null,
        },
        routeId: {
            type: [Number, String],
            default: null,
        },
        routeWorkflowTaskId: {
            type: [Number, String],
            default: null,
        },
        modifiedByUser: {
            type: Boolean,
            default: false,
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    data() {
        return {
            workflowStatusLinear: ['draft', 'review', 'approval', 'release', 'finalised', 'archived'],
            workflowStatusIcons: ['mdi-pen', 'mdi-eye-outline', 'mdi-shield-check-outline', 'mdi-draw', 'mdi-check', 'mdi-archive-outline'],
            workflowActivityDialog: false,
            workflowTasksDataTableExpanded: [],
            assignmentDialog: false,
            baseResponseDialog: false,
            assignmentDialogContent: {
                sourceStatus: null,
                targetStatus: null,
                title: null,
                notificationSubjectDisabled: false,
                notificationSubject: null,
                notificationContent: null,
                notificationDueDate: new Date().toISOString().substring(0, 10),
                text: null,
                notificationEmail: null,
                items: [],
                selectedPersonIds: [],
                submitButtonText: null,
                submitButtonClass: null,
                ignoreAssignmentPersons: false,
            },
            errorMessage: false,
            workflowTasksDataTableHeader: [
                { text: this.$t('created'), value: 'createdAt', sortable: true },
                { text: this.$t('vvtProcessEditor.workflow.history.fromName'), value: 'fromName', sortable: true },
                { text: this.$t('vvtProcessEditor.workflow.history.sourceStatus'), value: 'sourceStatus', sortable: true },
                { text: this.$t('vvtProcessEditor.workflow.history.targetStatus'), value: 'targetStatus', sortable: true },
                { text: this.$t('vvtProcessEditor.workflow.history.toName'), value: 'toName', sortable: true },
                { text: this.$t('vvtProcessEditor.workflow.history.dueDate'), value: 'dueDate', sortable: true },
                { text: this.$t('vvtProcessEditor.workflow.history.lastReminder'), value: 'lastReminder', sortable: true },
                { text: this.$t('vvtProcessEditor.workflow.history.status'), value: 'status', sortable: true },
            ]
        };
    },
    computed: {
        ...mapGetters({
            get: 'processingActivityModel/getProperty',
            workflowTasks: 'tasks/getWorkflowTasks',
            activityHasWorkflowTask: 'tasks/hasWorkflowTasks',
            workflowMode: 'processingActivityModel/getWorkflowModeState',
            getCompanyGroupSettings: 'companyGroupSettings/get',
            getMercureWorkflowUpdateNotifications: 'mercure/getMercureWorkflowUpdateNotifications'
        }),
        userRoles () {
            return getRoles();
        },
        userDetails () {
            return getDetails();
        },
        getWorkflowUpdate () {
            return this.getMercureWorkflowUpdateNotifications(this.get('id'), getDetails().id);
        },
        workflowTasksDataTable () {
            return this.workflowTasks.map(x => {
                return {
                    id: x.id,
                    status: this.$t('vvtProcessEditor.workflow.history.statusItems.' + x.status),
                    notificationStatus: x.notificationStatus,
                    sourceStatus: x.statusFrom === 'init' ? '-' : this.$t('states.' + x.statusFrom),
                    targetStatus: this.$t('states.' + x.statusTo),
                    dueDate: x.dueDate ? this.getFormatedDate(x.dueDate) : null,
                    updatedAt: x && x.updatedAt ? this.getFormatedDateTime(x.updatedAt) : null,
                    createdAt: x && x.createdAt ? this.getFormatedDateTime(x.createdAt) : null,
                    lastReminder: x && x.lastReminder ? this.getFormatedDateTime(x.lastReminder) : '-',
                    fromName: this.getFromName(x.taskData),
                    toName: this.getToName(x.taskData),
                    description: x.taskData.description
                };
            });
        },
        startingWorkflowTargetStatus () {
            return this.status === 'draft' ? 'draft' : this.workflowStatusLinear[this.currentWorkflowStepIndex];
        },
        currentWorkflowStepIndex () {
            return this.workflowStatusLinear.findIndex(x => x === this.status);
        },
        nextWorkflowStepIndex () {
            return (this.currentWorkflowStepIndex + 1) >= this.workflowStatusLinear.length ? 0 : this.currentWorkflowStepIndex + 1;
        },
        sendNotificationDisabled () {
            return this.errors.items.length > 0 || (!this.assignmentDialogContent.ignoreAssignmentPersons && !this.leaUserSelected) || this.$wait.is('fetch notification template') || this.$wait.is('workflow step') || !this.canEditWorkflow || this.modifiedByUser || 
                    (
                        (!this.assignmentDialogContent.selectedPersonIds.length || !this.assignmentDialogContent.items.length) && 
                        (!this.assignmentDialogContent.notificationEmail || !this.assignmentDialogContent.notificationEmail.trim() === '')
                    )
        },
        leaUserSelected () {
            if (this.$ssoMode) {
                return !!this.assignmentDialogContent.items.find(x => x.email) === true;
            }
            return !!this.assignmentDialogContent.items.find(x => x.isUser) === true;
        },
        datePickerMinDate () {
            return this.getFormatedDate(new Date(), 'datePicker');
        },
        showInitWorkflowButton () {
            if( this.$hasRole('ROLE_WORKFLOW_INIT') ) {
                return !this.activityHasWorkflowTask
            }

            return false;
        },
        dpiaRequirement () {
            return this.get('dpiaRequirement') === 'HIGH';
        },
        canEditWorkflow () {
            return this.get('canEditWorkflow');
        },
        status () {
            return this.get('status');
        },
        assignedPersons: {
            get() {
                let contactItems = this.get('contactItems');
                contactItems = contactItems.map(x => {
                    const prefix = x.title ? x.title + ' ' : '';
                    x.fullName = `${prefix}${x.firstName} ${x.lastName}`;
                    return x;
                });
                return contactItems;
            },
            set(val) {
                this.set({ property: 'contactItems', data: val }); // remove?
            },
        },
        assignedOwners () {
            return this.assignedPersons.filter(x => x.category === 1);
        },
        assignedEditors () {
            return this.assignedPersons.filter(x => x.category === 2);
        },
        assignedCoordinators () {
            return this.assignedPersons.filter(x => x.category === 3);
        },
        assignedConsultants () {
            return [];
        }
    },
    methods: {
        ...mapActions({
            fetchWorkflowTasks: 'tasks/fetchWorkflowTasksByRecordId',
            toggleWorkflow: 'processingActivityModel/toggleWorkflowModeState',
            setSilent: 'processingActivityModel/setPropertySilent',
            fetchNotificationByAlias: 'companyGroupNotification/fetchByAlias',
            clearMercureTopicMessages: 'mercure/clearTopicMessages'
        }),
        getFromName(taskData) {
            return taskData.fromUser.id === null ? this.$t('fromLeaResubmission') : (taskData.fromUser.title ? taskData.fromUser.title + ' ' : '') + taskData.fromUser.firstName + ' ' + taskData.fromUser.lastName;
        },
        getToName(taskData) {
            // old workflow tasks have deprecated json values in db
            if(Object.prototype.hasOwnProperty.call(taskData, 'toUser')) {
                return !taskData.toUser.id && taskData.toUser.email ? taskData.toUser.email : (taskData.toUser.title ? taskData.toUser.title + ' ' : '') + taskData.toUser.firstName + ' ' + taskData.toUser.lastName;
            }
            return !taskData.toContact.userId && taskData.toContact.email ? taskData.toContact.email : (taskData.toContact.title ? taskData.toContact.title + ' ' : '') + taskData.toContact.firstName + ' ' + taskData.toContact.lastName;
        },
        taskTableSort (items, index, isDesc) {
            const firstSortIndex = index[0];
            const firstSortDesc = isDesc[0];
            items.sort((a, b) => {
                if (firstSortIndex === 'updatedAt' || firstSortIndex === 'dueDate') {
                    if (!firstSortDesc) {
                        return new Date(a[firstSortIndex]) - new Date(b[firstSortIndex]);
                    } else {
                        return new Date(b[firstSortIndex]) - new Date(a[firstSortIndex]);
                    }
                } else {
                    if(!firstSortDesc && a[firstSortIndex] < b[firstSortIndex]) { return -1; }
                    if(!firstSortDesc && a[firstSortIndex] > b[firstSortIndex]) { return 1; }
                    if(firstSortDesc && b[firstSortIndex] < a[firstSortIndex]) { return -1; }
                    if(firstSortDesc && b[firstSortIndex] > a[firstSortIndex]) { return 1; }
                }
            });
            return items;
        },
        onClickWorkflowTask (val, props) {
            props.expand(!props.isExpanded);
        },
        personIsAssignable(person) {
            return person.isUser || (this.$ssoMode && person.email);
        },
        openAssignmentDialog (itemStatus, targetStatus) {
            if (itemStatus === 'init' && ['archived', 'finalised'].includes(targetStatus)) {
                targetStatus = 'draft';
            }
            let translationVar = 'edit_workflow_' + itemStatus;
            let translationVarTransition = 'to_' + targetStatus;
            let notificationTemplateAlias = translationVar + '_' + translationVarTransition;
            if (this.dpiaRequirement && targetStatus === 'finalised') {
                notificationTemplateAlias = notificationTemplateAlias + '_dpiaRequirement';
            }

            let dueDate = new Date();
            if (notificationTemplateAlias) {
                this.$wait.start('fetch notification template');
                this.fetchNotificationByAlias({alias: notificationTemplateAlias}).then((e) => {
                    this.$wait.end('fetch notification template');
                    if (e.data && e.data !== '') {
                        this.assignmentDialogContent.notificationSubject = e.data.title;
                        this.assignmentDialogContent.notificationSubjectDisabled = !Object.prototype.hasOwnProperty.call(e.data, 'title') && e.data.title.trim() !== '';
                        this.assignmentDialogContent.notificationContent = e.data.content;
                        if (Object.prototype.hasOwnProperty.call(e.data, 'deadline')) {
                            dueDate.setDate(dueDate.getDate() + parseInt(e.data.deadline));
                        }
                    } else {
                        this.assignmentDialogContent.notificationSubject = '';
                        this.assignmentDialogContent.notificationSubjectDisabled = false;
                        this.assignmentDialogContent.notificationContent = '';
                    }
                    this.assignmentDialogContent.notificationDueDate = this.getFormatedDate(dueDate, 'datePicker');
                    this.$validator.validateAll();
                }).catch(() => {
                    this.$wait.end('fetch notification template');
                });
            }

            let assignedPersons =   targetStatus === 'draft' ? this.assignedEditors :
                                    targetStatus ===  'review' ? this.assignedCoordinators :
                                    targetStatus ===  'approval' ? this.assignedConsultants :
                                    targetStatus ===  'release' ? this.assignedOwners :
                                    targetStatus ===  'finalised' ? this.assignedConsultants : [];
            let ignoreAssignmentPersons = ['approval', 'archived', 'finalised'].includes(targetStatus);
            this.assignmentDialogContent.ignoreAssignmentPersons = ignoreAssignmentPersons;
            this.assignmentDialogContent.sourceStatus = itemStatus;
            this.assignmentDialogContent.targetStatus = targetStatus;
            this.assignmentDialogContent.title = this.$t('vvtProcessEditor.workflow.' + translationVar + '.' + translationVarTransition + '.title');
            if (targetStatus === 'finalised') {
                this.assignmentDialogContent.text = this.$tc('vvtProcessEditor.workflow.' + translationVar + '.' + translationVarTransition +  '.info', this.dpiaRequirement ? 2 : 1);
            } else if (ignoreAssignmentPersons) {
                this.assignmentDialogContent.text = this.$t('vvtProcessEditor.workflow.' + translationVar + '.' + translationVarTransition +  '.info');
            } else if (assignedPersons.length > -1) {
                this.assignmentDialogContent.text = this.$tc('vvtProcessEditor.workflow.' + translationVar + '.' + translationVarTransition +  '.info', assignedPersons.length);
            }
            if (ignoreAssignmentPersons && this.getCompanyGroupSettings('workflowConsultantEmail') === null) {
                this.assignmentDialogContent.text = this.$t('vvtProcessEditor.workflow.missingWorkflowConsultantEmail');
            }

            this.assignmentDialogContent.notificationEmail = (targetStatus === 'approval' || targetStatus === 'finalised' || targetStatus === 'archived') ? this.getCompanyGroupSettings('workflowConsultantEmail') : null;
            this.assignmentDialogContent.items = assignedPersons;
            let assignedPersonsWithLeaLogin = assignedPersons ? assignedPersons.filter(x => x.isUser) : [];
            let assignedPersonsWithLeaEmail = assignedPersons ? assignedPersons.filter(x => x.email) : [];
            if (this.$ssoMode) {
                this.assignmentDialogContent.selectedPersonIds = assignedPersonsWithLeaEmail.map(x => x.contactId);
            } else {
                this.assignmentDialogContent.selectedPersonIds = assignedPersonsWithLeaLogin.map(x => x.contactId);
            }
            this.assignmentDialogContent.submitButtonText = this.$t('vvtProcessEditor.workflow.' + translationVar + '.' + translationVarTransition +  '.nextWorkflowStepButtonText');
            this.assignmentDialogContent.submitButtonClass = itemStatus === 'init' ? 'success' : 
                                    targetStatus === 'draft' ? 'warning lighten-1 primary--text text--darken-1' :
                                    itemStatus === 'draft' && targetStatus ===  'review' ? 'success' :
                                    targetStatus ===  'review' ? 'warning lighten-3 primary--text text--darken-1' :
                                    targetStatus ===  'archived' ? '' : 'success';
            
            this.assignmentDialog = true;
        },
        setWorkflowAction () {
            if (this.canEditWorkflow && !this.modifiedByUser && 
                (
                    (this.assignmentDialogContent.selectedPersonIds && this.assignmentDialogContent.selectedPersonIds.length) || 
                    (this.assignmentDialogContent.notificationEmail && this.assignmentDialogContent.notificationEmail.trim() !== '')
                )
                ) {
                this.$wait.start('workflow step');
                const request = {
                    recordId: this.get('id'),
                    sourceStatus: this.assignmentDialogContent.sourceStatus,
                    targetStatus: this.assignmentDialogContent.targetStatus,
                    targetPersons: this.assignmentDialogContent.selectedPersonIds,
                    dueDate: this.assignmentDialogContent.notificationDueDate,
                    subject: this.assignmentDialogContent.notificationSubject,
                    content: this.assignmentDialogContent.notificationContent,
                    taskData: {
                        sourceStatus: this.assignmentDialogContent.sourceStatus,
                        targetStatus: this.assignmentDialogContent.targetStatus,
                        useExternalEmail: !!this.assignmentDialogContent.notificationEmail === true
                    }
                };
                http.post('api/processingActivity/workflow/action', request)
                .then(response => {
                    if (response.data.success) {
                        this.assignmentDialog = false;
                    }  else {
                        this.errorMessage = true;
                    }
                    if (response.data) {
                        this.setRecordProperties(response.data.subject);
                        this.fetchTasks();
                    }
                    this.$wait.end('workflow step');
                })
                .catch(() => {
                    this.$wait.end('workflow step');
                    this.errorMessage = true;
                })
            }
        },
        setRecordProperties (subject) {
            if(subject) {
                this.setSilent({ property: 'status', data: subject.status });
                this.setSilent({ property: 'authorityReportRelevance', data: subject.authorityReportRelevance });
                this.setSilent({ property: 'updatedAt', data: subject.updatedAt });
                this.setSilent({ property: 'canRead', data: subject.canRead });
                this.setSilent({ property: 'canEdit', data: subject.canEdit });
                this.setSilent({ property: 'canEditExtended', data: subject.canEditExtended });
                this.setSilent({ property: 'canEditWorkflow', data: subject.canEditWorkflow });
                this.setSilent({ property: 'canDelete', data: subject.canDelete });
                this.setSilent({ property: 'canDeleteWithoutPrecondition', data: subject.canDeleteWithoutPrecondition });
                if (subject.contactItems) {
                    this.setSilent({ property: 'contactItems', data: subject.contactItems });
                }
            }
        },
        getFullName(item) {
            return item && item.departmentContact
                ? item.departmentContact.firstName +
                      ' ' +
                      item.departmentContact.lastName
                : 'N/A';
        },
        async fetchTasks() {
            if (this.workflowMode) {
                this.$wait.start('fetch tasks');
                if (this.routeId) {
                    this.fetchWorkflowTasks(this.routeId).then((response) => {
                        this.setRecordProperties(response.record);
                        this.clearMercureTopicMessages('workflowUpdate');
                        this.$wait.end('fetch tasks');
                    });
                }
            }
        },
        getFormatedDate(date, format) {
            format = format ? dateTypes[format] : dateTypes.fullDate;
            if (date instanceof Date && date.getTime()) {
                return formatDate(date, format);
            }
            return formatDate(parseISO(date), format);
        },
        getFormatedDateTime(date) {
            return formatDate(parseISO(date), dateTypes.fullDateTime);
        }
    },
    
    watch: {
        getWorkflowUpdate: {
            handler(to, from) {
                if (to && to.length && to.length !== from.length) {
                    this.fetchTasks();
                }
            },
            deep: true,
        },
    },
    mounted() {
        if (this.editorMode === 'singleEditMode') {
            this.fetchTasks();
        }
    }
};
</script>
