<template>
  <div>
    <v-timeline-item
      color="secondary"
      :icon-color="timelineIconColor"
      fill-dot
      :icon="itemIcon">
      <div class="mr-3">
          <v-card :elevation="1" color="transparent">
              <v-card-text class="py-1 d-block text-h6" :class="timelineTextColor">{{ itemTitle }}</v-card-text>
          </v-card>
          <v-card v-if="workflowActionEnabled" color="transparent" :elevation="0">
              <v-card-text class="text-center">
                  <v-icon color="primary" class="pb-3" large v-text="['finalised', 'archived'].includes(itemStatus) ? 'mdi-restart' : 'mdi-chevron-double-down'" />
                  <p v-text="nextStepText" />
              </v-card-text>
              <slot
                name="actions"
              ></slot>
          </v-card>
          <v-card v-else-if="itemStatus !== 'finalised' && itemStatus !== 'archived'" color="transparent" :elevation="0">
              <v-card-text class="text-center pb-0">
                  <v-icon color="primary" class="pb-0" small v-text="'mdi-chevron-double-down'" />
              </v-card-text>
          </v-card>
      </div>
    </v-timeline-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    itemStatus: {
      type: String,
      default: 'init'
    },
    itemIcon: {
      type: String,
      default: 'mdi-help-circle-outline'
    },
    targetStatus: {
      type: String,
      default: 'draft'
    },
    itemTitle: {
      type: String,
      default: null
    },
    nextStepText: {
      type: String,
      default: null
    },
    workflowActionEnabled: {
      type: Boolean,
      default: false
    },
    workflowStatusLinear: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
        get: 'processingActivityModel/getProperty'
    }),
    activityStatus () {
        return this.get('status');
    },
    timelineIconColor () {
        if (this.activityStatus === this.itemStatus) {
            return ['finalised', 'archived'].includes(this.activityStatus) ? 'success' : 'primary';
        }
        const indexCurrent = this.workflowStatusLinear.findIndex(x => x === this.activityStatus);
        const indexTarget = this.workflowStatusLinear.findIndex(x => x === this.itemStatus);
        if (indexCurrent > indexTarget) {
            return 'success';
        }
        return 'grey';
    },
    timelineTextColor () {
        if (this.activityStatus === this.itemStatus) {
            return ['finalised', 'archived'].includes(this.activityStatus) ? 'success--text' : 'primary--text';
        }
        const indexCurrent = this.workflowStatusLinear.findIndex(x => x === this.activityStatus);
        const indexTarget = this.workflowStatusLinear.findIndex(x => x === this.itemStatus);
        if (indexCurrent > indexTarget) {
            return 'success--text';
        }
        return null;
    }
  }
}
</script>